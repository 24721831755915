const reducers = (state = {}, action) => {
  switch (action.type) {
    case 'DISPATCH_ALLOW':
      return {
        ...state,
        allow: action.allow
      }
    case 'DISPATCH_WAIT':
      return {
        ...state,
        wait: action.wait
      }
    default:
      return state
  }
}

export default reducers
