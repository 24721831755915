import React from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { ReactTablev6, Modal, shapes } from '@cimpress/react-components'
import IconDownloadBottom from '@cimpress-technology/react-streamline-icons/lib/IconDownloadBottom'
import { ExportToCsv } from 'export-to-csv'
import { formatClassificationsToCsv } from '../lib/export/classification'
import ClassificationDetails from './classificationDetails'
import AccountSelect from './accountSelect'
import { matchSorter } from 'match-sorter'
import {
  dispatchSelectedProduct,
  dispatchSetHoveredRow
} from '../redux/classification/actions'
import SearchBar from './searchBar'
const { Spinner } = shapes

export class ActiveClassifications extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  onRowClick = (rowInfo) => {
    const selectedInfo = this.props.activeClassificationsRecords.allActiveClassificationRecords.find(
      (record) => record.links.self.href === rowInfo.original.selfLink
    )
    dispatchSelectedProduct(rowInfo.index, selectedInfo)
  }

  handleClosePopup = () => {
    dispatchSelectedProduct(null, {})
  }

  render() {
    if (this.props.loadingActiveClassificationsData)
      return <div style={{ textAlign: 'center' }}>{<Spinner />} Loading...</div>
    return (
      <div style={{ overflowWrap: 'break-word' }}>
        <div style={{ display: 'flex' }}>
          {!this.props.isVistaprintIor && (
            <legend style={{ borderBottom: 'none' }}>
              {this.props.iorText + ' IOR'}
            </legend>
          )}

          {!this.props.loadingActiveClassificationsData &&
            this.props.activeClassificationsRecords
              .allActiveClassificationRecords &&
            this.props.activeClassificationsRecords
              .allActiveClassificationRecords.length !== 0 &&
            !this.props.isVistaprintIor && (
              <div
                style={{
                  justifyContent: 'flex-end',
                  whiteSpace: 'nowrap',
                  marginTop: '10px'
                }}
                onClick={this.downloadClassificationCsv}
              >
                <a className="clickable" href={() => false}>
                  <IconDownloadBottom weight="fill"></IconDownloadBottom>
                  &nbsp;
                  {'Export All Visible Classifications as .csv file'}
                </a>
              </div>
            )}
        </div>
        <div style={{ display: 'flex' }}>
          {this.props.isVistaprintIor && (
            <legend style={{ borderBottom: 'none' }}>
              <AccountSelect />
            </legend>
          )}
        </div>
        <div className="flex column bg-toned">
          {this.props.selectedImporterOfRecord.id === 'pixartprinting' &&
            this.props.activeClassificationsRecords
              .allActiveClassificationRecords && <SearchBar />}
          {this.getTable()}
        </div>

        <Modal
          status="success"
          size={'lg'}
          show={this.props.selected !== null}
          onRequestHide={this.handleClosePopup}
          title={
            this.props.selectedClassification &&
            this.props.selectedClassification.mcpSku &&
            this.props.selectedClassification.productName &&
            (this.props.selectedClassification.productName.length < 20
              ? `${this.props.selectedClassification.mcpSku}, ${this.props.selectedClassification.productName} `
              : `${
                  this.props.selectedClassification.mcpSku
                }, ${this.props.selectedClassification.productName.slice(
                  0,
                  20
                )} ...`)
          }
          closeButton={true}
          footer={
            <button className="btn btn-default" onClick={this.handleClosePopup}>
              Close
            </button>
          }
        >
          <ClassificationDetails />
        </Modal>
      </div>
    )
  }

  downloadClassificationCsv = () => {
    const data = formatClassificationsToCsv(
      this.props.activeClassificationsRecords.allActiveClassificationRecords
    )
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      useKeysAsHeaders: true
    }
    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(data)
  }

  getTable = () => {
    let {
      activeClassificationsRecords,
      loadingActiveClassificationsData
    } = this.props
    if (loadingActiveClassificationsData) {
      return <div style={{ textAlign: 'center' }}>{<Spinner />} Loading...</div>
    }

    if (this.props.isVistaprintIor && !this.props.selectedAccount) return

    let maxPage = activeClassificationsRecords.classificationRecordsToDisplay
      ? activeClassificationsRecords.classificationRecordsToDisplay.length
      : 0
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginRight: '25px',
          marginLeft: '25px',
          marginBottom: '25px',
          marginTop: '25px'
        }}
      >
        <ReactTablev6
          defaultFilterMethod={(filter, rows) =>
            matchSorter(rows, filter.value, { keys: [filter.id] })
          }
          noData={
            !this.props.loadingActiveClassificationsData ||
            !activeClassificationsRecords.classificationRecordsToDisplay ||
            activeClassificationsRecords.classificationRecordsToDisplay.length <
              1
          }
          noDataText={'No classifications loaded for viewing.'}
          columns={
            activeClassificationsRecords.displayColumnNames
              ? activeClassificationsRecords.displayColumnNames
              : []
          }
          data={
            activeClassificationsRecords.classificationRecordsToDisplay
              ? activeClassificationsRecords.classificationRecordsToDisplay
              : []
          }
          showPagination={true}
          showPaginationTop={true}
          showPaginationBottom={false}
          usePagination={true}
          filterable={true}
          pageSizeOptions={
            maxPage > 100 ? [25, 50, 100, maxPage] : [25, 50, 100]
          }
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onDoubleClick: (e) => {
                  this.onRowClick(rowInfo)
                },
                onMouseEnter: (e) => {
                  dispatchSetHoveredRow(rowInfo.index)
                },
                onMouseLeave: (e) => {
                  dispatchSetHoveredRow(null)
                },
                style: {
                  cursor: 'pointer',
                  background:
                    rowInfo.index === this.props.hoveredRow ||
                    (rowInfo.index === this.props.selected &&
                      (this.props.selected !== null) === true)
                      ? '#00AFEC'
                      : 'white',
                  color:
                    rowInfo.index === this.props.hoveredRow ||
                    (rowInfo.index === this.props.selected &&
                      (this.props.selected !== null) === true)
                      ? 'white'
                      : 'black'
                }
              }
            } else {
              return {}
            }
          }}
        />
      </div>
    )
  }
}

export default connect((state) => {
  return {
    selected: state.classification.selected,
    selectedClassification: state.classification.selectedClassification,
    hoveredRow: state.classification.hoveredRow,
    activeClassificationsRecords:
      state.classification.activeClassificationsRecords,
    loadingActiveClassificationsData:
      state.classification.loadingActiveClassificationsData,
    iorText:
      state.classification.selectedImporterOfRecord &&
      state.classification.selectedImporterOfRecord.name,
    selectedImporterOfRecord: state.classification.selectedImporterOfRecord,
    isVistaprintIor: state.classification.isVistaprintIor,
    selectedAccount: state.classification.selectedAccount,
    accounts: state.classification.accounts
  }
})(ActiveClassifications)
