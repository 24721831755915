import { formatData } from '../../lib/common/getAttributes'

const reducers = (state = {}, action) => {
  switch (action.type) {
    case 'CLASSIFICATIONS':
      return {
        ...state,
        classifications: action.classifications
      }
    case 'FILE':
      return {
        ...state,
        file: action.file
      }
    case 'IMPORTERS_OF_RECORD':
      return {
        ...state,
        importersOfRecord: action.importersOfRecord
      }
    case 'SELECT_IMPORTER_OF_RECORD':
      return {
        ...state,
        selectedImporterOfRecord: action.selectedImporterOfRecord,
        isVistaprintIor: action.selectedImporterOfRecord.id === 'vistaprint'
      }
    case 'SET_LOADING_ACTIVE_CLASSIFICATION_DATA':
      return {
        ...state,
        loadingActiveClassificationsData:
          action.loadingActiveClassificationsData
      }
    case 'SET_ACTIVE_CLASSIFICATION_RECORDS': {
      const allActiveClassificationRecords = action.classeResponse

      let fieldsForDisplay = allActiveClassificationRecords.map((record) => {
        return {
          selfLink: record.links.self.href,
          mcpSku: record.mcpSku,
          productName: record.productName,
          customsClassificationId: record.classificationId,
          accountName: record.accountName,
          attributes: record.attributes
        }
      })

      const formattedResponse = formatData(fieldsForDisplay)

      const displayColumnNames = [
        {
          Header: 'Account',
          accessor: 'accountName',
          id: 'accountName',
          minWidth: 140,
          filterAll: true
        },
        {
          Header: 'Fulfillment SKU',
          accessor: 'mcpSku',
          id: 'mcpSku',
          minWidth: 200,
          filterAll: true
        },
        {
          Header: 'Product',
          accessor: 'productName',
          id: 'productName',
          minWidth: 300,
          filterAll: true
        },
        ...formattedResponse.orderedItemsToDisplay
      ]

      const classificationRecordsToDisplay = formattedResponse.data

      const activeClassificationsRecords = {
        displayColumnNames,
        allActiveClassificationRecords,
        classificationRecordsToDisplay
      }

      return {
        ...state,
        activeClassificationsRecords
      }
    }

    case 'SET_FILTERED_CLASSIFICATION_RECORDS': {
      const selectedAccountName = action.selectedAccountName

      if (
        state.activeClassificationsRecords &&
        state.activeClassificationsRecords.allActiveClassificationRecords
      ) {
        const recordsForSelectedAccount = state.activeClassificationsRecords.allActiveClassificationRecords.filter(
          (record) => {
            return record.accountName === selectedAccountName
          }
        )

        const activeClassificationsRecords = {
          ...state.activeClassificationsRecords,
          classificationRecordsToDisplay: recordsForSelectedAccount,
          filterInProgress: false
        }

        return {
          ...state,
          activeClassificationsRecords
        }
      }

      return state
    }

    case 'SET_SELECTED_PRODUCT':
      return {
        ...state,
        selected: action.selected,
        selectedClassification: action.selectedClassification
      }

    case 'SET_SELECTED_HOVERED_ROW_PRODUCT_TO_DISPLAY':
      return {
        ...state,
        hoveredRow: action.hoveredRow
      }

    case 'SET_CLASSIFICATION_DETAIL_TO_DISPLAY': {
      return {
        ...state,
        selectedClassification: {
          ...state.selectedClassification,
          htsData: action.htsData,
          productLink: action.productLink,
          manufacturerProductLink: action.manufacturerProductLink
        }
      }
    }

    case 'SET_SELECTED_ACCOUNT': {
      return {
        ...state,
        selectedAccount: action.selectedAccount
      }
    }

    case 'SET_ACCOUNT_DATA_TO_DISPLAY': {
      return {
        ...state,
        accounts: action.accounts
      }
    }

    case 'STORE_CLASSIFICATIONS_FOR_SELECTED_IOR': {
      return {
        ...state,
        classificationsForSelectedImporterOfRecord: action.data
      }
    }

    default:
      return state
  }
}

export default reducers
