import React from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Button } from '@cimpress/react-components'
import { useDropzone } from 'react-dropzone'

import * as spreadsheet from '../lib/spreadsheet/spreadsheet'
import { dispatchError, dispatchMessage } from '../redux/notifications/actions'
import { dispatchFile } from '../redux/classification/actions'

function FileDropzone() {
  const onDrop = (acceptedFiles) => {
    dispatchFile(acceptedFiles[0])
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv'
  })

  return (
    <div
      {...getRootProps()}
      className="bg-toned-dark"
      style={{ marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}
    >
      <input {...getInputProps()} />
      <h3 style={{ marginTop: '60px', marginLeft: '15px', fontWeight: 'bold' }}>
        <i className="fa fa-upload"></i>&nbsp; Drag Classifications List Here
      </h3>
      <h4
        style={{
          marginBottom: '60px',
          marginLeft: '35px',
          fontWeight: 'normal'
        }}
      >
        or click here to browse
      </h4>
    </div>
  )
}

export class SpreadsheetSelector extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.state = {
      file: undefined
    }
  }

  onChange(e) {
    const file = e.target.files[0]
    this.setState({
      file: file
    })
  }

  onClick() {
    try {
      this.clearNotifications()
      spreadsheet.handleUpload(this.props.file)
    } catch (err) {
      dispatchError(err)
    }
  }

  clearNotifications() {
    dispatchError(null)
    dispatchMessage(null)
  }

  render() {
    let templateHref

    if (this.props.submissionType === 'request') {
      templateHref = '/templates/request_template.csv'
    } else if (this.props.submissionType === 'classification') {
      templateHref = '/templates/classification_template.csv'
    }

    return (
      <div className="col-md-6">
        <p>&nbsp;</p>
        <h2>
          <i className="fa fa-cloud-upload"></i>&nbsp; Upload Classifications
        </h2>
        <div className="bg-blank flex column" style={{ marginTop: '15px' }}>
          <FileDropzone />
          <Button
            variant="primary"
            id="upload"
            onClick={this.onClick}
            disabled={!this.props.file}
            style={{
              marginBottom: '15px',
              marginLeft: '15px',
              marginRight: '15px'
            }}
          >
            {this.props.file
              ? `Upload ${this.props.file.name}`
              : 'Please select a .CSV file'}
          </Button>
        </div>
        <h3 style={{ marginTop: '70px', fontWeight: 'bold' }}>
          <i className="fa fa-question-circle"></i>&nbsp; Not sure what
          information you need?
        </h3>
        <h4 style={{ fontWeight: 'normal', marginLeft: '20px' }}>
          <a href={templateHref} download={'classification_template.csv'}>
            Download a template here
          </a>
        </h4>
        <h3 style={{ marginTop: '70px', fontWeight: 'bold' }}>
          <i className="fa fa-question-circle"></i>&nbsp; Looking for
          instructions on how to use this application?
        </h3>
        <h4 style={{ fontWeight: 'normal', marginLeft: '20px' }}>
          <a href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/15145402408/Customs+Classification+User+Guide">
            Read our documentation here.
          </a>
        </h4>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    classifications: state.classification.classifications,
    file: state.classification.file,
    submissionType: state.classification.selectedImporterOfRecord.submissionType
  }
})(SpreadsheetSelector)
