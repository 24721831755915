const _ = require('lodash')

export const formatClassificationsToCsv = (allActiveClassificationRecords) => {
  const sortedClassificationRecords = _.orderBy(
    allActiveClassificationRecords,
    ['mcpSku'],
    ['asc']
  )
  return sortedClassificationRecords.map((classification) => {
    const countriesOfClassification =
      classification.countryClassifications &&
      Object.keys(classification.countryClassifications).join(';')
    const htsCode =
      countriesOfClassification &&
      classification.countryClassifications[
        Object.keys(classification.countryClassifications)[0]
      ].htsCode
    const preferentialOrigin =
      countriesOfClassification &&
      Object.keys(classification.countryClassifications)
        .filter(
          (country) =>
            classification.countryClassifications[country]
              .preferentialOrigin === true
        )
        .join(';')
    const nonPreferentialOrigin =
      countriesOfClassification &&
      Object.keys(classification.countryClassifications)
        .filter(
          (country) =>
            classification.countryClassifications[country]
              .preferentialOrigin === false
        )
        .join(';')
    const attributes =
      classification.attributes &&
      Object.keys(classification.attributes)
        .map((key) => {
          return key + ':' + classification.attributes[key]
        })
        .join(';')
    return {
      'Fulfillment SKU (ie. CIM-ZGD74VRM)': classification.mcpSku,
      'Country of Origin (2 letter country code)':
        classification.countryOfOrigin,
      'Countries of Classification (2 letter country code; separated by semicolon)':
        countriesOfClassification || '',
      'HTS Code': htsCode || '',
      'Countries that are Preferential Origin (separated by semicolon)':
        preferentialOrigin || '',
      'Countries that are not Preferential Origin (separated by semicolon)':
        nonPreferentialOrigin || '',
      'Attribute (ie. Substrate:GlossyAluminium)': attributes || ''
    }
  })
}
