const reducers = (state = {}, action) => {
  switch (action.type) {
    case 'DISPATCH_ERROR':
      return {
        ...state,
        error: action.error
      }
    case 'DISPATCH_MESSAGE':
      return {
        ...state,
        message: action.message
      }
    default:
      return state
  }
}

export default reducers
