import store from '../index'

export const dispatchAllow = (allow) => {
  store.dispatch({ type: 'DISPATCH_ALLOW', allow })
}

export const dispatchWait = (wait) => {
  store.dispatch({ type: 'DISPATCH_WAIT', wait })
}

export const dispatchApp = ({ allow, wait }) => {
  store.dispatch({ type: 'DISPATCH_ALLOW', allow })
  store.dispatch({ type: 'DISPATCH_WAIT', wait })
}
