import store from '../../redux/index'
import { isEqual } from 'lodash'

export function formatClassifications(classifications) {
  const {
    id,
    submissionType
  } = store.getState().classification.selectedImporterOfRecord

  let resultClassifications
  if (submissionType === 'request') {
    resultClassifications = classifications.map((classification) => {
      return {
        accountId: classification.accountId,
        mcpSku: classification.mcpSku,
        productDescription: classification.productDescription,
        color: classification.color,
        gender: classification.gender,
        countryOfOrigin: classification.countryOfOrigin,
        manufacturerProductLink: classification.manufacturerProductLink,
        size: classification.size,
        apparelBuild: classification.apparelBuild,
        averageSellingPrice: formatAverageSellingPrice(
          classification.averageSellingPrice
        ),
        attributes: formatAttributes(classification.attributes),
        importerOfRecord: id,
        materialComposition: [
          formatMaterial(classification.material),
          formatMaterial(classification.material2),
          formatMaterial(classification.material3)
        ].filter((x) => x)
      }
    })
  } else if (submissionType === 'classification') {
    resultClassifications = classifications.reduce(
      (formattedClassifications, classification) => {
        let foundClassification = formattedClassifications.find(
          (potentialClassification) => {
            return (
              potentialClassification.mcpSku === classification.mcpSku &&
              isEqual(
                potentialClassification.attributes,
                classification.attributes
              ) &&
              potentialClassification.countryOfOrigin ===
                classification.countryOfOrigin
            )
          }
        )
        if (foundClassification) {
          foundClassification.countryClassifications = {
            ...foundClassification.countryClassifications,
            [classification.countryOfClassification]: {
              htsCode: classification.htsCode,
              preferentialOrigin: stringToBoolean(
                classification.preferentialOrigin
              )
            }
          }
        } else {
          formattedClassifications.push({
            mcpSku: classification.mcpSku,
            attributes: formatAttributes(classification.attributes),
            importerOfRecord: id,
            countryOfOrigin: classification.countryOfOrigin,
            countryClassifications: {
              [classification.countryOfClassification]: {
                htsCode: classification.htsCode,
                preferentialOrigin: stringToBoolean(
                  classification.preferentialOrigin
                )
              }
            }
          })
        }
        return formattedClassifications
      },
      []
    )
  }
  return formatEmptyStringsAsUndefined(resultClassifications)
}

function stringToBoolean(str) {
  if (typeof str !== 'string') return str
  if (str === '') return undefined
  return str === 'true'
}

function formatEmptyStringsAsUndefined(classifications) {
  return classifications.map((classification) => {
    const cleanClassification = {}
    Object.keys(classification).forEach((key) => {
      if (key === 'countryClassifications') {
        for (const country in classification[key]) {
          if (classification[key][country]['htsCode'] === '') {
            classification[key][country]['htsCode'] = undefined
          }
        }
      }
      if (classification[key] !== '') {
        cleanClassification[key] = classification[key]
      }
    })
    return cleanClassification
  })
}

function formatAttributes(attributes) {
  if (!attributes) return
  return Object.keys(attributes).reduce((acc, curr) => {
    acc[curr.trim()] = attributes[curr].trim()
    return acc
  }, {})
}

function formatMaterial(material) {
  if (!material) return
  return {
    type: material.split(':')[0].trim(),
    percentage: parseInt(material.split(':')[1]) || 100
  }
}

function formatAverageSellingPrice(averageSellingPrice) {
  if (!averageSellingPrice) return
  const parts = averageSellingPrice.split(':').map((s) => s.trim())
  let [currency, amount] = parts
  currency = currency.toUpperCase()
  amount = parseFloat(amount)
  return { currency, amount }
}
