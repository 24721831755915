import { makeAuthenticatedRequest } from '../authentication/authenticationProxy'
import { CLASSE_BASEURL } from '../../app-config.json'
import store from '../../redux/index'

export function classificationRequests(classifications) {
  const {
    submissionType
  } = store.getState().classification.selectedImporterOfRecord

  let url

  if (submissionType === 'request') {
    url = `${CLASSE_BASEURL}/api/v2/classificationRequests`
  } else if (submissionType === 'classification') {
    url = `${CLASSE_BASEURL}/api/v2/classifications`
  }

  return makeAuthenticatedRequest({
    url,
    method: 'POST',
    data: classifications
  })
}

export function getIor(classifications) {
  return makeAuthenticatedRequest({
    url: `${CLASSE_BASEURL}/api/v1/importersOfRecord`,
    method: 'GET'
  })
}
