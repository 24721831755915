const state = {
  classifications: [],
  file: undefined,
  importersOfRecord: [],
  selectedImporterOfRecord: undefined,
  isVistaprintIor: false,
  activeClassificationsRecords: {},
  loadingActiveClassificationsData: false,
  selected: null,
  selectedClassification: {},
  hoveredRow: {},
  selectedAccount: undefined,
  accounts: [],
  classificationsForSelectedImporterOfRecord: []
}

export default state
