import { flattenDeep, isEmpty, keys } from 'lodash'
export function formatData(data) {
  const attributeColumns = []
  let count = 0
  let atrributeNameColumnAccessor, attributeValueColumnAccessor

  let classificationIdColumn

  data.forEach((row) => {
    const { attributes, customsClassificationId } = row
    count = 0
    if (customsClassificationId && classificationIdColumn === undefined) {
      classificationIdColumn = {
        Header: 'Customs Classification ID',
        accessor: 'customsClassificationId',
        id: 'customsClassificationId',
        minWidth: 140,
        filterAll: true
      }
    }
    if (attributes && !isEmpty(attributes)) {
      keys(attributes).forEach((attribute) => {
        atrributeNameColumnAccessor = `attributeName-${count}`
        attributeValueColumnAccessor = `attributeValue-${count}`

        if (
          !attributeNameExists(atrributeNameColumnAccessor, attributeColumns)
        ) {
          attributeColumns.push({
            Header: 'Attribute Name',
            accessor: `${atrributeNameColumnAccessor}`,
            id: `${atrributeNameColumnAccessor}`,
            minWidth: 140,
            filterAll: true
          })
          attributeColumns.push({
            Header: 'Attribute Value',
            accessor: `${attributeValueColumnAccessor}`,
            id: `${attributeValueColumnAccessor}`,
            minWidth: 140,
            filterAll: true
          })
        }
        row[atrributeNameColumnAccessor] = attribute
        row[attributeValueColumnAccessor] = row.attributes[attribute]
        count++
      })
    }
  })

  data = flattenDeep(data)

  let orderedItemsToDisplay = [...attributeColumns]

  if (classificationIdColumn !== undefined) {
    orderedItemsToDisplay.splice(0, 0, classificationIdColumn)
  }

  return {
    data,
    orderedItemsToDisplay
  }
}

function attributeNameExists(attributeName, attributeColumns) {
  return attributeColumns.find((attr) => {
    return attr.accessor === attributeName
  })
}
