import { classificationRequests, getIor } from './classeProxy'
import { formatClassifications } from './classeFormatter'
import { dispatchError } from '../../redux/notifications/actions'
import { dispatchImportersOfRecord } from '../../redux/classification/actions'
import { makeAuthenticatedRequest } from '../authentication/authenticationProxy'
import { flattenDeep, chunk } from 'lodash'
import { CLASSE_BASEURL } from '../../app-config.json'
import { get } from 'lodash'

const CHUNK_SIZE = 10

export async function sendClassifications(classifications) {
  let response = []
  const formattedClassifications = formatClassifications(classifications)
  const chunks = chunk(formattedClassifications, CHUNK_SIZE)

  for (const classificationsChunk of chunks) {
    const chunkResponse = await classificationRequests(classificationsChunk)
    response = response.concat(chunkResponse)
  }

  let errorCount = 0
  let errors = []
  response &&
    response.length > 0 &&
    response.forEach((classificationResponse) => {
      if (classificationResponse.failureInfo) {
        if (
          classificationResponse.failureInfo.cause &&
          classificationResponse.failureInfo.cause.reason ===
            'Request has already been processed.'
        ) {
          errors.push(
            `${classificationResponse.mcpSku} has already been sent for classification`
          )
        } else {
          errorCount++
          errors.push(
            `Error classifying ${classificationResponse.mcpSku}: ${classificationResponse.failureInfo.cause.reason}`
          )
        }
      }
    })

  if (errorCount > 0) {
    let errorMessage = errors.join('\n')

    throw new Error(
      `Please fix the following errors and resubmit or contact support.\n\n${errorMessage}`
    )
  }
}

export async function getImportersOfRecord() {
  let { importersOfRecord } = await getIor()

  if (importersOfRecord.length === 0) {
    dispatchError(`You do not have access to any Importer of Record`)
  }

  dispatchImportersOfRecord(importersOfRecord)
}

export async function getClassificationDetails(selectedClassification) {
  let response
  let getClassificationLink

  if (selectedClassification.links.self.href) {
    getClassificationLink = selectedClassification.links.self.href
  }
  try {
    if (getClassificationLink) {
      response = await makeAuthenticatedRequest({
        url: `${getClassificationLink}?collapseClassifications=true`,
        method: 'GET'
      })
    }
  } catch (err) {}

  return response
}

export async function getClassifications(ior, accountId, page) {
  let classifications = []
  let nextPage = page

  const iorQuery = `importerOfRecord=${ior}`
  const accountQuery = accountId ? `&accountId=${accountId}` : ''

  try {
    if (ior) {
      const { classifications: data, links } = await makeAuthenticatedRequest({
        url: `${CLASSE_BASEURL}/api/v2/classifications?${iorQuery}${accountQuery}`,
        method: 'GET'
      })
      nextPage = get(links, 'nextPage.href', null)
      classifications = classifications.concat(data)
    }

    while (nextPage) {
      const { classifications: data, links } = await makeAuthenticatedRequest({
        url: nextPage,
        method: 'GET'
      })
      nextPage = get(links, 'nextPage.href', null)
      classifications = classifications.concat(data)
    }
  } catch (err) {
    console.log(err)
  }

  return flattenDeep(classifications)
}
