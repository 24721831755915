import React from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import SpreadsheetSelector from './spreadsheetSelector'
import ClassificationDisplay from './classificationDisplay'
import { shapes } from '@cimpress/react-components'

const { Robot } = shapes

export class SubmitClassificationsWrapper extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  render() {
    if (!this.props.selectedImporterOfRecord) {
      return (
        <div className="ior-select-screen-robot">
          <legend style={{ borderBottom: 'none' }}>
            Welcome to Customs UI! Please select an Importer of Record from
            drop-down above.
          </legend>
          <Robot status="success" alternate />
        </div>
      )
    } else if (this.props.classifications.length > 0) {
      return <ClassificationDisplay />
    } else {
      return <SpreadsheetSelector />
    }
  }
}

export default connect((state) => {
  return {
    classifications: state.classification.classifications,
    selectedImporterOfRecord: state.classification.selectedImporterOfRecord
  }
})(SubmitClassificationsWrapper)
