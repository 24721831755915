import { combineReducers } from 'redux'

import auth from './authentication/reducers'
import classification from './classification/reducers'
import app from './app/reducers'
import notifications from './notifications/reducers'
// Import all reducers and combine them here
export default combineReducers({
  auth,
  classification,
  app,
  notifications
})
