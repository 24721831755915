import React, { Component } from 'react'
import { Select } from '@cimpress/react-components'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { getClassificationBasedOnAccount } from '../lib/accounts/accountUtils'

export class AccountSelect extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  async setAccount(option) {
    await getClassificationBasedOnAccount(
      option,
      this.props.selectedImporterOfRecord.id
    )
  }

  render() {
    return (
      <Select
        isDisabled={this.props.loadingActiveClassificationsData}
        label={'Account'}
        id={'accountSelect'}
        name={'accountSelect'}
        placeholder={'Select an account'}
        value={
          this.props.selectedAccount ? this.props.selectedAccount : undefined
        }
        options={this.props.accounts}
        onChange={this.setAccount}
        containerClassName={'account-select-control'}
      />
    )
  }
}

export default connect((state) => {
  return {
    loadingActiveClassificationsData:
      state.classification.loadingActiveClassificationsData,
    accounts: state.classification.accounts,
    selectedAccount: state.classification.selectedAccount,
    selectedImporterOfRecord: state.classification.selectedImporterOfRecord
  }
})(AccountSelect)
