const reducers = (state = {}, action) => {
  switch (action.type) {
    case 'LOG_IN':
      return {
        ...state,
        isAuthenticated: true,
        profile: action.profile
      }
    case 'LOG_OUT':
      return {
        ...state,
        isAuthenticated: false,
        profile: null
      }
    default:
      return state
  }
}

export default reducers
