import cookies from 'js-cookie'
import {
  dispatchSetActiveClassificationRecords,
  dispatchLoadingActiveClassificationsData,
  dispatchAccounts,
  dispatchSelectedAccount
} from '../../redux/classification/actions'
import { dispatchError } from '../../redux/notifications/actions'
import { getClassifications } from '../classe/classeService'
import { getActiveAccounts, getPermissions } from './accountService'
const _ = require('lodash')

const ACCOUNT_SELECTION_COOKIE_KEY = 'account_selection'

export async function getAccounts() {
  let accountData, accountPermission
  try {
    await Promise.all([getActiveAccounts(), getPermissions()]).then(
      ([accountResponse, permission]) => {
        accountData = accountResponse
        accountPermission = permission.account ? permission.account : null
      }
    )
  } catch (err) {
    dispatchError(err)
  }

  let accounts = []
  if (accountData.length > 0) {
    accountData.forEach((account) => {
      accounts.push({
        value: account.name,
        label: account.name,
        accountId: account.accountId
      })
    })

    accounts = _.orderBy(accounts, ['label'])

    if (hasAllAccess(accountPermission)) {
      accounts.unshift({
        value: 'ALL',
        label: 'Display Classifications of all accounts',
        accountId: 'ALL'
      })
    }

    return accounts
  }
}

function hasAllAccess(permission) {
  if (!permission) return false
  for (const type of permission) {
    if (
      _.get(type, 'identifier') === '*' &&
      _.get(type, 'permissions').includes('read:account')
    )
      return true
  }
  return false
}

export async function getAccountFromCookie(ior, accounts) {
  const cookie = cookies.get(ACCOUNT_SELECTION_COOKIE_KEY)
  if (!(cookie && accounts.length)) return

  const account = accounts.find((loc) => loc.accountId === cookie)
  if (account) await getClassificationBasedOnAccount(account, ior.id)
}

export async function getClassificationBasedOnAccount(account, ior) {
  dispatchSelectedAccount(account)

  if (account.accountId !== 'ALL')
    cookies.set(ACCOUNT_SELECTION_COOKIE_KEY, account.accountId)

  dispatchLoadingActiveClassificationsData(true)
  const response =
    account.accountId === 'ALL'
      ? await getClassifications(ior)
      : await getClassifications(ior, account.accountId)

  if (response) {
    dispatchSetActiveClassificationRecords(response)
  } else {
    dispatchSetActiveClassificationRecords([])
  }
  dispatchLoadingActiveClassificationsData(false)
}

export async function getAllAccounts(selectedImporterOfRecord) {
  dispatchLoadingActiveClassificationsData(true)
  const accounts = await getAccounts()
  dispatchAccounts(accounts)
  await getAccountFromCookie(selectedImporterOfRecord, accounts)
  dispatchLoadingActiveClassificationsData(false)
}
