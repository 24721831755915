import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { ReactTablev6, shapes } from '@cimpress/react-components'
import { dispatchClassificationDetails } from '../redux/classification/actions'
import { PRODUCT_CATALOG_BASEURL } from '../app-config.json'
import { getClassificationDetails } from '../lib/classe/classeService'
import { getProductFamilySku } from '../lib/products/productFamily'
import { dispatchWait } from '../redux/app/actions'
const _ = require('lodash')
const { Spinner } = shapes

export class ClassificationDetails extends Component {
  constructor() {
    super()
    autobind(this)
  }

  async componentDidMount() {
    let classificationFromCCS, familySku
    dispatchWait(true)
    await Promise.all([
      getClassificationDetails(this.props.selectedClassification),
      getProductFamilySku(this.props.selectedClassification.mcpSku)
    ]).then(([classifications, referenceId]) => {
      classificationFromCCS = classifications
      familySku = referenceId
    })

    let {
      countryClassifications,
      manufacturerProductLink
    } = classificationFromCCS
      ? classificationFromCCS
      : this.props.selectedClassification
    let htsDataArray = []
    countryClassifications = _.omit(countryClassifications, ['undefined'])

    if (countryClassifications) {
      Object.keys(countryClassifications).forEach((key) =>
        htsDataArray.push({
          country: key ? key : '',
          htsCode: countryClassifications[key].htsCode
            ? countryClassifications[key].htsCode
            : '',
          preferentialOrigin:
            countryClassifications[key].preferentialOrigin !== undefined
              ? countryClassifications[key].preferentialOrigin.toString()
              : ''
        })
      )
    }

    const productLink = familySku
      ? `${PRODUCT_CATALOG_BASEURL}/family/${familySku}?mcpsku=${this.props.selectedClassification.mcpSku}`
      : `${PRODUCT_CATALOG_BASEURL}/search?searchCriteria={"searchBoxQuery":"${this.props.selectedClassification.mcpSku}"}`

    dispatchClassificationDetails(
      htsDataArray,
      productLink,
      manufacturerProductLink
    )

    dispatchWait(false)
  }

  getNoDataText() {
    return (
      <div className="noDataText">
        No Classifications are available.
        <br></br>
        <br></br>
        Please reach out to
        <a href={`mailto:${`LogisticsTradeCompliance@cimpress.com`}`}>
          {' '}
          LogisticsTradeCompliance@cimpress.com{' '}
        </a>
        for more info.
      </div>
    )
  }

  getColumns() {
    let htsHeaders = [
      {
        Header: 'Customs Territory',
        accessor: 'country',
        minWidth: 60
      },

      { Header: 'Tariff Code', accessor: 'htsCode', minWidth: 60 }
    ]

    if (this.props.selectedImporterOfRecord.id !== 'vistaprint') {
      htsHeaders.push({
        Header: 'Preferential Origin',
        accessor: 'preferentialOrigin',
        minWidth: 60
      })
    }

    return htsHeaders
  }

  getSortedData() {
    const { htsData } = this.props
    return _.orderBy(htsData, ['country'], ['asc'])
  }

  getHtsTable() {
    return (
      <div>
        <ReactTablev6
          noData={!this.props.htsData}
          noDataText={this.getNoDataText()}
          columns={this.getColumns()}
          data={this.getSortedData()}
          showPagination={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          usePagination={true}
        />
      </div>
    )
  }

  getDescription() {
    return (
      <div>
        <h3>
          {this.props.selectedClassification.accountName ? (
            <div className="listItem">
              <strong>Account</strong>:{' '}
              {this.props.selectedClassification.accountName}
            </div>
          ) : (
            ''
          )}
        </h3>
        <h3>
          {this.props.selectedClassification.countryOfOrigin ? (
            <div className="listItem">
              <strong>Country of Origin</strong>:{' '}
              {this.props.selectedClassification.countryOfOrigin}
            </div>
          ) : (
            ''
          )}
        </h3>
        <h3>
          {this.props.selectedClassification.productName ? (
            <div className="listItem">
              <strong>Product</strong>:{' '}
              {this.props.selectedClassification.productName}
            </div>
          ) : (
            ''
          )}
        </h3>
        <h3>
          {this.props.selectedClassification.mcpSku ? (
            <div className="listItem">
              <strong>{'FulfillmentSKU'}</strong>:{' '}
              <a
                href={this.props.productLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.selectedClassification.mcpSku}
              </a>
            </div>
          ) : (
            ''
          )}
        </h3>
        <h3>
          {this.props.selectedClassification.attributes ? (
            <div className="listItem">
              <strong>Attributes</strong>:{' '}
              {Object.keys(this.props.selectedClassification.attributes).map(
                (oneKey, i) => {
                  return (
                    <div className="listItem-item" oneKey={i}>
                      {`${oneKey}`}:{' '}
                      {`${this.props.selectedClassification.attributes[oneKey]}`}
                    </div>
                  )
                }
              )}
            </div>
          ) : (
            ''
          )}
        </h3>
        <h3>
          {this.props.manufacturerProductLink ? (
            <div className="listItem">
              <strong>Manufacturer Product Link</strong>:{' '}
              <a
                href={`${this.props.manufacturerProductLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.manufacturerProductLink}
              </a>
            </div>
          ) : (
            ''
          )}
        </h3>
        <h3>
          {this.props.selectedClassification.materialComposition ? (
            <div className="listItem">
              <strong>Materials</strong>:{' '}
              {this.props.selectedClassification.materialComposition.map(
                (item, i) => {
                  return (
                    <div className="listItem-item">{`${item.type}: ${item.percentage}%`}</div>
                  )
                }
              )}
            </div>
          ) : (
            ''
          )}
        </h3>
      </div>
    )
  }

  render() {
    let elementToDisplay
    if (this.props.wait) {
      elementToDisplay = (
        <div style={{ textAlign: 'center' }}>{<Spinner />} Loading...</div>
      )
    } else {
      elementToDisplay = (
        <div className="flex column bg-toned">
          {this.getDescription()}
          {this.getHtsTable()}
        </div>
      )
    }
    return <div>{elementToDisplay}</div>
  }
}

export default connect((state) => {
  return {
    htsData: state.classification.selectedClassification.htsData,
    selectedClassification: state.classification.selectedClassification,
    selectedImporterOfRecord: state.classification.selectedImporterOfRecord,
    productLink: state.classification.selectedClassification.productLink,
    manufacturerProductLink:
      state.classification.selectedClassification.manufacturerProductLink,
    wait: state.app.wait
  }
})(ClassificationDetails)
