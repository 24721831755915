import { ACCOUNT_BASEURL, COAM_BASEURL } from '../../app-config.json'
import { makeAuthenticatedRequest } from '../authentication/authenticationProxy'

export async function getActiveAccounts() {
  let response
  try {
    const {
      _embedded: { item }
    } = await makeAuthenticatedRequest({
      url: `${ACCOUNT_BASEURL}/api/v1/accounts`,
      method: 'GET'
    })
    response = item
  } catch (err) {
    throw new Error(
      `Error retreiving account information from account service: ${err}`
    )
  }

  return response
}

export async function getPermissions() {
  let response
  try {
    response = await makeAuthenticatedRequest({
      url: `${COAM_BASEURL}/v1/principals/self/permissions?resourceTypes=account`
    })
  } catch (err) {
    throw new Error(`Error retreiving permission information from COAM: ${err}`)
  }

  return response
}
