import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { TextField, Button } from '@cimpress/react-components'
import { dispatchSetActiveClassificationRecords } from '../redux/classification/actions'

export class SearchBar extends Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.state = {
      searchTariffCode: ''
    }
  }

  onChange(e) {
    this.setState({ searchTariffCode: e.target.value })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.onButtonClick()
    }
  }

  onButtonClick() {
    let classifications
    if (this.state.searchTariffCode === '') {
      classifications = this.props.classificationsForSelectedImporterOfRecord
    } else {
      classifications = this.props.classificationsForSelectedImporterOfRecord.filter(
        (classification) => {
          return Object.keys(classification.countryClassifications).some(
            (country) => {
              return (
                classification.countryClassifications[country].htsCode ===
                this.state.searchTariffCode
              )
            }
          )
        }
      )
    }
    dispatchSetActiveClassificationRecords(classifications)
  }

  render() {
    return (
      <div>
        <TextField
          label="Search By Tariff Code"
          value={this.state.searchTariffCode}
          onChange={this.onChange}
          onKeyUp={this.handleKeyPress.bind(this)}
          rightAddon={<Button onClick={this.onButtonClick}>Search</Button>}
        />
      </div>
    )
  }
}

export default connect((state) => {
  return {
    classificationsForSelectedImporterOfRecord:
      state.classification.classificationsForSelectedImporterOfRecord
  }
})(SearchBar)
