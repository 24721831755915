const currencyCodes = require('currency-codes')

const ALLOWED_GENDER = ['M', 'W', 'B', 'G', 'Y', 'U']
const ALLOWED_APPAREL_BUILD = ['K', 'W', 'O']
const VALID_COUNTRY_CODE_REGEX = /^[A-Z]{2}$/

export function isInvalidRequest(classifications) {
  const accountSkuAttributeSet = new Set()
  return classifications.map((classification) => {
    const {
      mcpSku,
      material,
      material2,
      material3,
      attributes,
      gender,
      apparelBuild,
      averageSellingPrice,
      accountId
    } = classification
    let reasons = []
    let isInvalid = false
    let sortedAttributes

    if (attributes) {
      sortedAttributes = Object.keys(attributes)
        .sort()
        .reduce((obj, key) => {
          obj[key] = attributes[key]
          return obj
        }, {})
    }

    if (
      accountSkuAttributeSet.has(
        JSON.stringify({ accountId, mcpSku, sortedAttributes })
      )
    ) {
      isInvalid = true
      reasons.push(
        'There are duplicated entries with same AccountIds, SKUs and Attributes.'
      )
    } else {
      accountSkuAttributeSet.add(
        JSON.stringify({ accountId, mcpSku, sortedAttributes })
      )
    }

    const numberOfMaterials =
      (material ? 1 : 0) + (material2 ? 1 : 0) + (material3 ? 1 : 0)
    if (!mcpSku || !numberOfMaterials) {
      isInvalid = true
      reasons.push(
        'Some classifications are missing Fulfillment SKU and/or Material.'
      )
    }
    if (numberOfMaterials > 1) {
      if (
        (material && !getMaterialPercentage(material)) ||
        (material2 && !getMaterialPercentage(material2)) ||
        (material3 && !getMaterialPercentage(material3))
      ) {
        isInvalid = true
        reasons.push(
          'Must specify material percentage(integer) if more than one material.'
        )
      } else if (
        getMaterialPercentage(material) +
          getMaterialPercentage(material2) +
          getMaterialPercentage(material3) <
        75
      ) {
        isInvalid = true
        reasons.push(
          'The sum of the material percentages must be greater than 75.'
        )
      }
    } else {
      const oneMaterial = material || material2 || material3
      if (
        getMaterialPercentage(oneMaterial) &&
        getMaterialPercentage(oneMaterial) < 75
      ) {
        isInvalid = true
        reasons.push('If one material, the percentage must be greater than 75.')
      }
    }

    if (gender && !ALLOWED_GENDER.includes(gender)) {
      isInvalid = true
      reasons.push(`Allowed gender are ${ALLOWED_GENDER}.`)
    }

    if (apparelBuild && !ALLOWED_APPAREL_BUILD.includes(apparelBuild)) {
      isInvalid = true
      reasons.push(`Allowed apparelBuild are ${ALLOWED_APPAREL_BUILD}.`)
    }

    if (averageSellingPrice) {
      const parts = averageSellingPrice.split(':').map((s) => s.trim())
      const [currency, amount] = parts
      if (
        parts.length !== 2 ||
        !currencyCodes.code(currency) ||
        isNaN(amount)
      ) {
        isInvalid = true
        reasons.push(
          'Incorrect Format. Format should be currency:amount eg. USD:12.99. Currency should be a valid ISO 4217 code and Amount should be a number'
        )
      } else if (parseFloat(amount) === 0) {
        isInvalid = true
        reasons.push(
          'Some classifications have an average selling price amount of 0'
        )
      }
    } else {
      isInvalid = true
      reasons.push(`Some classifications are missing average selling price.`)
    }

    if (!accountId) {
      isInvalid = true
      reasons.push('Some classifications are missing accountId.')
    }

    return { ...classification, invalid: { isInvalid, reasons } }
  })
}

export function isInvalidClassification(classifications) {
  return classifications.map((classification) => {
    const { mcpSku, countryOfClassification, htsCode } = classification
    let reasons = []
    let isInvalid = false

    if (!mcpSku) {
      isInvalid = true
      reasons.push('Classification needs a valid SKU.')
    }

    if (!VALID_COUNTRY_CODE_REGEX.test(countryOfClassification) && mcpSku) {
      isInvalid = true
      reasons.push(
        `A country of classification with two uppercase letters (e.g., US) is required for SKU: ${mcpSku}.`
      )
    }

    if (!htsCode && countryOfClassification && mcpSku) {
      isInvalid = true
      reasons.push(
        `${countryOfClassification} is missing an HTS code for SKU: ${mcpSku}.`
      )
    }

    return { ...classification, invalid: { isInvalid, reasons } }
  })
}

function getMaterialPercentage(material) {
  if (!material) return 0
  return parseInt(material.split(':')[1])
}
