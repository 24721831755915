import { makeAuthenticatedRequest } from '../authentication/authenticationProxy'
import { PRODUCT_FAMILY_BASEURL } from '../../app-config.json'

export async function getProductFamilySku(mcpSku) {
  try {
    const {
      familySummary: { referenceId }
    } = await makeAuthenticatedRequest({
      url: `${PRODUCT_FAMILY_BASEURL}/v2/product-families/mcpsku?mcpsku=${mcpSku}`,
      method: 'GET'
    })
    return referenceId
  } catch (err) {}

  return
}
