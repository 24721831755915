// Import auth initial state
import auth from './authentication/state'
import classification from './classification/state'
import app from './app/state'
import notifications from './notifications/state'
// Merge all state into an initial state object and export it

const state = {
  auth,
  classification,
  app,
  notifications
}

export default state
