import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import $ from 'jquery'

$(document).ready(() => {
  let rootElement = document.getElementById('root')
  if (rootElement) {
    ReactDOM.hydrate(<Root />, rootElement)
  }
})
