import store from '../../redux/index'

export function logIn() {
  let auth0 = store.getState().auth.auth0
  if (!auth0.isLoggedIn()) {
    return auth0.ensureAuthentication().then(function (success) {
      if (success) {
        let profile = getProfile()
        store.dispatch({ type: 'LOG_IN', profile })
        return success
      } else {
        store.dispatch({ type: 'LOG_OUT' })
      }
    })
  } else {
    return Promise.resolve(true)
  }
}

export function logOut() {
  let auth0 = store.getState().auth.auth0
  store.dispatch({ type: 'LOG_OUT' })
  return auth0.logout('/', true)
}

export function isLoggedIn() {
  let auth0 = store.getState().auth.auth0
  return auth0.isLoggedIn()
}

export function getProfile() {
  let auth0 = store.getState().auth.auth0
  return auth0.getProfile()
}

export function getAccessToken() {
  let auth0 = store.getState().auth.auth0
  return auth0.getAccessToken()
}
