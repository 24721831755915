import * as authenticationService from './auth'
import axios from 'axios'
import { merge } from 'lodash'

export async function makeAuthenticatedRequest(request) {
  try {
    const { data } = await makeAuthenticatedRequestFullResponse(request)
    return data
  } catch (err) {
    if (window.newrelic) {
      window.newrelic.setCustomAttribute('url', request.url)
      window.newrelic.noticeError(err)
    }
    if (err.response) {
      throw formatErrorMessage(err.response)
    }
    throw err
  }
}

export async function makeAuthenticatedRequestFullResponse(request) {
  await authenticationService.logIn()
  return axios.request(
    merge({}, request, {
      headers: {
        authorization: `Bearer ${authenticationService.getAccessToken()}`
      }
    })
  )
}

function formatErrorMessage(err) {
  if (err && err.data && err.data.error) {
    return err.data.error
  } else {
    const error = new Error(
      'Request failed: ' +
        err.status +
        ' ' +
        err.statusText +
        ', ' +
        JSON.stringify(err.data)
    )
    error.status = err.status
    return error
  }
}
