import React from 'react'
import store from './redux/index'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { CssLoader } from '@cimpress/react-components'
import App from './App'

export default class Root extends React.Component {
  render() {
    return (
      <CssLoader>
        <Provider store={store}>
          <BrowserRouter>
            <App {...this.props} />
          </BrowserRouter>
        </Provider>
      </CssLoader>
    )
  }
}
